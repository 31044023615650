// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_mb_0__YiOzE {\n  margin-bottom: 0;\n}\n\n.Header_ml_10__RyLAs {\n  margin-left: 10px;\n}\n\n.Header_span_content__3x7tb {\n  display: flex;\n  justify-content: space-between;\n}\n\n.Header_back_btn__ijpxX {\n  position: absolute;\n  left: 10px;\n  cursor: pointer;\n  z-index: 1;\n  margin-left: 50px;\n}\n\n.Header_cursor_pointer__MwvtE {\n  cursor: pointer;\n}\n\n.Header_fs__Q-0Ps {\n  font-size: 20px;\n}", "",{"version":3,"sources":["webpack://./src/css/Header.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EAGE,aAAa;EAGL,8BAA8B;AACxC;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,eAAe;EACf,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".mb_0 {\n  margin-bottom: 0;\n}\n\n.ml_10 {\n  margin-left: 10px;\n}\n\n.span_content {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n}\n\n.back_btn {\n  position: absolute;\n  left: 10px;\n  cursor: pointer;\n  z-index: 1;\n  margin-left: 50px;\n}\n\n.cursor_pointer {\n  cursor: pointer;\n}\n\n.fs {\n  font-size: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mb_0": "Header_mb_0__YiOzE",
	"ml_10": "Header_ml_10__RyLAs",
	"span_content": "Header_span_content__3x7tb",
	"back_btn": "Header_back_btn__ijpxX",
	"cursor_pointer": "Header_cursor_pointer__MwvtE",
	"fs": "Header_fs__Q-0Ps"
};
export default ___CSS_LOADER_EXPORT___;
