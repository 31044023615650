// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Filter_wrapper__b6MQA {\n  font-size: 20px;\n}\n.Filter_wrapper__b6MQA .Filter_card__KPB0x {\n  text-align: center;\n  margin-bottom: 20px;\n  margin-top: 10px;\n}\n.Filter_wrapper__b6MQA .Filter_card__KPB0x .Filter_back_icon__EPDXI {\n  cursor: pointer;\n  position: absolute;\n  left: 10px;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/css/Filter.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,UAAU;EACV,eAAe;AACjB","sourcesContent":[".wrapper {\n  font-size: 20px;\n}\n.wrapper .card {\n  text-align: center;\n  margin-bottom: 20px;\n  margin-top: 10px;\n}\n.wrapper .card .back_icon {\n  cursor: pointer;\n  position: absolute;\n  left: 10px;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Filter_wrapper__b6MQA",
	"card": "Filter_card__KPB0x",
	"back_icon": "Filter_back_icon__EPDXI"
};
export default ___CSS_LOADER_EXPORT___;
