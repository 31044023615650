// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PipeRolling_dimentions__rv7Vw {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 5px;\n  padding: 0px 0 25px 0;\n}\n\n.PipeRolling_gosts__qGE8D {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 5px;\n  padding: 0px 0 25px 0;\n  gap: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/css/PipeRolling.module.css"],"names":[],"mappings":"AAAA;EAGE,aAAa;EAET,eAAe;EACnB,QAAQ;EACR,qBAAqB;AACvB;;AAEA;EAGE,aAAa;EAET,eAAe;EACnB,QAAQ;EACR,qBAAqB;EACrB,SAAS;AACX","sourcesContent":[".dimentions {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  gap: 5px;\n  padding: 0px 0 25px 0;\n}\n\n.gosts {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  gap: 5px;\n  padding: 0px 0 25px 0;\n  gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dimentions": "PipeRolling_dimentions__rv7Vw",
	"gosts": "PipeRolling_gosts__qGE8D"
};
export default ___CSS_LOADER_EXPORT___;
