export const fileFormats = ['.png', '.jpeg', '.pdf', '.txt']
export const domens = [
  '.ru',
  '.ua',
  '.by',
  '.kz',
  '.com',
  '.pro',
  '.org',
  '.io',
  '.net',
  '.su',
  '.uz',
  '.az',
  '.am',
  '.ge',
  '.kg',
  '.md',
  '.tj',
  '.tm'
]