// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EquipmentUnderPressure_mainVessel__kc4Kz {\n  font-weight: 600;\n}\n\n.EquipmentUnderPressure_mainFormGroup__5vWA1 {\n  padding: 20px 0 20px 60px;\n}\n\n.EquipmentUnderPressure_mainFormGroup__5vWA1 .EquipmentUnderPressure_dimentions__byuZq {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 5px;\n  padding: 0px 0 25px 0;\n}\n\n.EquipmentUnderPressure_mainFormGroup__5vWA1 .EquipmentUnderPressure_subVessel__2FpUX {\n  padding-top: 5px;\n}\n", "",{"version":3,"sources":["webpack://./src/css/EquipmentUnderPressure.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EAGE,aAAa;EAET,eAAe;EACnB,QAAQ;EACR,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".mainVessel {\n  font-weight: 600;\n}\n\n.mainFormGroup {\n  padding: 20px 0 20px 60px;\n}\n\n.mainFormGroup .dimentions {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  gap: 5px;\n  padding: 0px 0 25px 0;\n}\n\n.mainFormGroup .subVessel {\n  padding-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainVessel": "EquipmentUnderPressure_mainVessel__kc4Kz",
	"mainFormGroup": "EquipmentUnderPressure_mainFormGroup__5vWA1",
	"dimentions": "EquipmentUnderPressure_dimentions__byuZq",
	"subVessel": "EquipmentUnderPressure_subVessel__2FpUX"
};
export default ___CSS_LOADER_EXPORT___;
