// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Map_map__knI1Q {\n  height: 550px;\n  width: 1300px;\n}\n\na {\n  text-decoration: none;\n}\na .Map_company_item__2hAuY {\n  color: #000;\n  border-radius: 3px;\n  padding: 10px 5px 10px 10px;\n  margin-bottom: 5px;\n  background-color: #eeeff0;\n  font-weight: bolder;\n}\na .Map_company_item__2hAuY:hover {\n  background-color: #d3d6d9;\n}\n\n.Map_popup_wrap__S3tZf {\n  cursor: pointer;\n  text-align: center;\n}\n.Map_popup_wrap__S3tZf .Map_popup_city_name__Wkt6H {\n  margin-bottom: 5px;\n  font-weight: bold;\n}", "",{"version":3,"sources":["webpack://./src/components/map/style/Map.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,aAAA;AADJ;;AAGA;EACI,qBAAA;AAAJ;AAEG;EACC,WAAA;EACA,kBAAA;EACA,2BAAA;EACA,kBAAA;EACA,yBAAA;EACA,mBAAA;AAAJ;AAEI;EACI,yBAAA;AAAR;;AAKA;EACI,eAAA;EACA,kBAAA;AAFJ;AAII;EACI,kBAAA;EACA,iBAAA;AAFR","sourcesContent":["@import '../../../style/colors';\n\n.map{\n    height: 550px;\n    width: 1300px\n}\na{\n    text-decoration: none;\n   \n   .company_item{\n    color: #000;\n    border-radius: 3px; \n    padding: 10px 5px 10px 10px; \n    margin-bottom: 5px;\n    background-color: lighten($secondary-color, 55%);\n    font-weight: bolder;\n\n    &:hover{\n        background-color: lighten($secondary-color, 45%);\n    }\n} \n}\n\n.popup_wrap{\n    cursor: pointer; \n    text-align: center;\n\n    .popup_city_name{\n        margin-bottom: 5px;\n        font-weight: bold;\n        // font-size: 1.1rem;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"map": "Map_map__knI1Q",
	"company_item": "Map_company_item__2hAuY",
	"popup_wrap": "Map_popup_wrap__S3tZf",
	"popup_city_name": "Map_popup_city_name__Wkt6H"
};
export default ___CSS_LOADER_EXPORT___;
