// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Home_grey_color__1Plq9 {\n  color: grey;\n}\n\n.Home_back_btn__8PUKt {\n  position: absolute;\n  left: 10px;\n  cursor: pointer;\n  z-index: 1;\n}\n\n.Home_map__6yKpe {\n  position: relative;\n  margin: 0 auto;\n  width: 100%;\n  height: 1000px;\n  background: url(https://sthe.lotus1.org/wp-content/uploads/2023/01/map.png) center center no-repeat;\n  background-size: contain;\n}\n\n.Home_pin__8XCjm {\n  color: #BB2D3B;\n}\n\n.Home_city_name__9fVn- {\n  color: #000;\n}\n\n.Home_map_container__Opi8u {\n  display: flex;\n  justify-content: center;\n}", "",{"version":3,"sources":["webpack://./src/css/Home.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,WAAW;EACX,cAAc;EACd,mGAAmG;EACnG,wBAAwB;AAC1B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EAGE,aAAa;EAGL,uBAAuB;AACjC","sourcesContent":[".grey_color {\n  color: grey;\n}\n\n.back_btn {\n  position: absolute;\n  left: 10px;\n  cursor: pointer;\n  z-index: 1;\n}\n\n.map {\n  position: relative;\n  margin: 0 auto;\n  width: 100%;\n  height: 1000px;\n  background: url(https://sthe.lotus1.org/wp-content/uploads/2023/01/map.png) center center no-repeat;\n  background-size: contain;\n}\n\n.pin {\n  color: #BB2D3B;\n}\n\n.city_name {\n  color: #000;\n}\n\n.map_container {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grey_color": "Home_grey_color__1Plq9",
	"back_btn": "Home_back_btn__8PUKt",
	"map": "Home_map__6yKpe",
	"pin": "Home_pin__8XCjm",
	"city_name": "Home_city_name__9fVn-",
	"map_container": "Home_map_container__Opi8u"
};
export default ___CSS_LOADER_EXPORT___;
