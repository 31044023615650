// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Questionary_description__g-kgj {\n  display: flex;\n  justify-content: space-between;\n}\n\n.Questionary_mb_20__Pk5oN {\n  margin-bottom: 20px;\n}\n\n.Questionary_under_press_equip_body__xAY3z {\n  margin-bottom: 5px;\n  padding: 0 7px;\n}\n\n.Questionary_card__7X90v {\n  width: \"100%\";\n  text-align: center;\n  font-weight: bolder;\n  position: relative;\n}\n.Questionary_card__7X90v .Questionary_icon__0QOJ7 {\n  position: absolute;\n  left: 10px;\n  cursor: pointer;\n}\n\n.Questionary_fs_20__7toI6 {\n  font-size: 20px;\n}\n\n.Questionary_downloadIconDiv__tlngA {\n  text-align: center;\n  cursor: pointer;\n  color: #007bff;\n}", "",{"version":3,"sources":["webpack://./src/css/Questionary.module.css"],"names":[],"mappings":"AAAA;EAGE,aAAa;EAGL,8BAA8B;AACxC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,cAAc;AAChB","sourcesContent":[".description {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n}\n\n.mb_20 {\n  margin-bottom: 20px;\n}\n\n.under_press_equip_body {\n  margin-bottom: 5px;\n  padding: 0 7px;\n}\n\n.card {\n  width: \"100%\";\n  text-align: center;\n  font-weight: bolder;\n  position: relative;\n}\n.card .icon {\n  position: absolute;\n  left: 10px;\n  cursor: pointer;\n}\n\n.fs_20 {\n  font-size: 20px;\n}\n\n.downloadIconDiv {\n  text-align: center;\n  cursor: pointer;\n  color: #007bff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": "Questionary_description__g-kgj",
	"mb_20": "Questionary_mb_20__Pk5oN",
	"under_press_equip_body": "Questionary_under_press_equip_body__xAY3z",
	"card": "Questionary_card__7X90v",
	"icon": "Questionary_icon__0QOJ7",
	"fs_20": "Questionary_fs_20__7toI6",
	"downloadIconDiv": "Questionary_downloadIconDiv__tlngA"
};
export default ___CSS_LOADER_EXPORT___;
